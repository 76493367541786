<template>
  <v-flex
    offset-lg-1
    class="flex-margin"
  >
    <v-card>
      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <v-card-title
        class="card-title v-card-scroll"
        color="black"
      >
        {{ $t(`ServiceCard.${$route.params.id}.title`) }}
        <v-spacer />
        <div
          v-if="mobileBreakpoints()"
          class="d-flex"
          style="justify-content: end;"
        >
          <v-icon
            class="text-right"
            color="red"
            size="28"
            @click="$router.push('/')"
          >
            mdi-close
          </v-icon>
        </div>
      </v-card-title>

      <v-tabs
        v-model="selectedTab"
        color="green"
        active-class="white"
        background-color="#f4f3f3"
        grow
      >
        <v-tab
          v-for="(type, index) in reportType"
          :key="index"
        >
          {{ type.label }}
        </v-tab>
      </v-tabs>

      <v-row
        v-if="selectedTab > 0"
        class="ms-1 my-2 me-3"
      >
        <v-col cols="4">
          <v-select
            v-model="selectedYear"
            :hide-details="mobileBreakpoints()"
            :items="yearsArray"
            label="Year"
          />
        </v-col>
        <v-col
          cols="8"
          sm="4"
        >
          <v-menu
            ref="filterReportHubMenu"
            v-model="rangeMenu"
            :close-on-content-click="false"
            :return-value.sync="rangeDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="rangeDateText"
                multiple
                chips
                small-chips
                label="Filter range date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                class="shrink"
                clearable
                :hide-details="mobileBreakpoints()"
                @click:clear="closeDateRange()"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="rangeDate"
              :picker-date.sync="pickerDate"
              :allowed-dates="filterAllowedDates"
              no-title
              range
              scrollable
              :min="rangeDate[0] || `${selectedYear}-01-01`"
              :max="maxPickerDate"
            >
              <v-btn
                text
                color="primary"
                @click="closeDateRange()"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                :disabled="rangeDate.length < 2"
                @click="changeFilterDate()"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="search"
            class="shrink"
            clearable
            prepend-icon="mdi-magnify"
            :label="$t('AlertExPost.search')"
            single-line
            hide-details
            :style="{height: mobileBreakpoints() ? '50px' : '60px'}"
          />
        </v-col>
      </v-row>

      <v-tabs-items v-model="selectedTab">
        <v-tab-item
          v-for="(type, index) in reportType"
          :key="index"
        >
          <v-card>
            <v-data-table
              :headers="tabHeaders"
              :items="filteredItems"
              :search="search"
              sort-by="reportDate"
              :sort-desc="true"
              class="elevation-1"
              :footer-props="{
                itemsPerPageAllText: $t('Table.all'),
                itemsPerPageText: $t('Table.rowsPerPage'),
                pageText: `{0}-{1} ${$t('Table.of')} {2}`
              }"
              :no-data-text="$t('Table.noData')"
            >
              <template v-slot:[`item.reportDate`]="{item}">
                {{ item.reportDate || '-' }}
              </template>
              <!-- <template v-slot:top>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('AlertExPost.search')"
                  class="mx-5 mb-5 mt-0 pt-5"
                  single-line
                  hide-details
                />
              </template> -->
              <template v-slot:[`item.view`]="{ item }">
                <v-btn
                  class="me-3"
                  small
                  outlined
                  color="success"
                  dark
                  @click="dispatchDownloadItem(item.s3_path)"
                >
                  PDF
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-flex>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { S3_BUCKETS } from '@/constants.js';
import moment from 'moment-business-days';

export default {
  data: () => ({
    overlay: false,
    rangeMenu: false,
    serviceName: '',
    selectedYear: '',
    selectedTab: 0,
    prevYearArray: [],
    latestReports: [],
    headers: [],
    rangeDate: [],
    reportType: [],
    itemsArray: [],
    yearsArray: [],
    allowedDates: [],
    pickerDate: null,
    search: null
  }),

  computed: {
    ...mapState(['app_user', 'policy', 'file_transfer']),
    tabHeaders() {
      return this.headers.filter(header => header.value !== 'reportType' || this.selectedTab === 0);
    },
    rangeDateText() {
      const start_date = this.rangeDate[0] ? moment(this.rangeDate[0]).format('YYYY-MM-DD') : '';
      const end_date = this.rangeDate[1] ? moment(this.rangeDate[1]).format('YYYY-MM-DD') : '';

      return this.rangeDate.length ? `${start_date} - ${end_date}` : '';
    },
    filteredItems() {
      return this.rangeDate.length < 2
        ? this.itemsArray
        : this.itemsArray.filter(e => {
          const start = moment(this.rangeDate[0]).format('YYYY-MM-DD');
          const end = moment(this.rangeDate[1]).format('YYYY-MM-DD');

          return moment(e.reportDate, 'YYYY-MM-DD').isSameOrAfter(start, 'day') && moment(e.reportDate, 'YYYY-MM-DD').isSameOrBefore(end, 'day');
        });
    },
    maxPickerDate() {
      return this.selectedYear.toString() === moment().format('YYYY') ? moment().format('YYYY-MM-DD') : `${this.selectedYear}-12-31`;
    }
  },

  watch: {
    async $route(to, from) {
      if (from.name === 'Report-Hub' && from.name === to.name) {
        this.overlay = false;
        this.rangeMenu = false;
        this.serviceName = '';
        this.selectedTab = {};
        this.latestReports = [];
        this.prevYearArray = [];
        this.headers = [];
        this.rangeDate = [];
        this.reportType = [];
        this.itemsArray = [];
        this.search = null;
        await this.fetchData();
      }
    },
    selectedTab(newTab) {
      this.refreshItems(newTab);
    },
    async selectedYear(newYear) {
      if (newYear) {
        this.overlay = true;
        this.search = null;
        this.rangeDate = [];
        this.pickerDate = `${newYear}-01`;
        this.allowedDates = this.fetchAllowedDates();
        await this.fetchData();
        this.overlay = false;
      }
    }
  },

  async created () {
    this.overlay = true;
    this.yearsArray = this.generateYearsArray();
    this.selectedYear = this.yearsArray[0];
    this.pickerDate = `${this.selectedYear}-01`;
    this.allowedDates = this.fetchAllowedDates();
    this.overlay = false;
  },

  methods: {
    ...mapActions(['storeFolderObjects', 'downloadItem']),
    async fetchData() {
      this.overlay = true;
      const reportHubServiceSelected = this.policy.appUserPolicy[0].REPORT_HUB.filter(e => e.service_id === this.$route.params.id && !e.father_id);

      if (this.selectedTab === 0) {
        this.prevYearArray = [];
        const params = {
          crm_id: this.app_user.crm_id,
          service_id: this.$route.params.id,
          folders: reportHubServiceSelected.map(e => e.node_name),
          year: this.selectedYear - 1
        };
        await this.storeFolderObjects(params);
        this.prevYearArray.push(...this.policy.folderObjects);
      }

      const params = {
        crm_id: this.app_user.crm_id,
        service_id: this.$route.params.id,
        folders: reportHubServiceSelected.map(e => e.node_name),
        year: this.selectedYear
      };
      await this.storeFolderObjects(params);

      this.generateTabs();

      if (!this.latestReports.length) {
        this.generateLatestReport();
      }

      this.refreshItems(this.selectedTab);

      // if (this.selectedTab !== 0) {
      //   this.generateTabs();
      // }

      this.headers = [
        { text: this.$t('Reports.type'), value: 'reportType', sortable: true },
        { text: this.$t('Reports.name'), value: 'ReportName', sortable: true },
        { text: this.$t('Reports.date'), value: 'reportDate', sortable: true },
        { text: this.$t('Reports.view'), value: 'view', align: 'end', sortable: false }
      ];

      this.overlay = false;
    },
    generateTabs() {
      this.serviceName = this.policy.folderObjects.map(path => path.split('/')[2])[0];

      this.policy.appUserPolicy.forEach(policyItem => {
        if (policyItem.REPORT_HUB.length > 0) {
          this.reportType = [
            { type: 'LatestReports', label: this.$t('Reports.latestReports') },
            ...policyItem.REPORT_HUB.map(e => {
              if (!e.father_id) {
                return { type: e.node_name, label: this.$t(`ServiceCard.${e.node_id}.title`), id: e.node_id };
              }
            }).filter(Boolean)
          ];
        }
      });
    },
    async dispatchDownloadItem(s3_path) {
      const params = {
        Bucket: S3_BUCKETS.application(),
        Key: s3_path
      };
      await this.downloadItem(params);
      window.open(this.file_transfer.downloadLink);
    },
    extractDate(str) {
      const datePattern = /\d{4}-\d{2}-\d{2}_\d{4}\.pdf$/;
      const match = str.match(datePattern);

      if (match) {
        return this.moment(match[0], 'YYYY-MM-DD_HHmm').format('YYYY-MM-DD HH:mm');
      }

      return null;
    },
    refreshItems(tab) {
      // console.log('YEARS', this.policy.appUserPolicy[0].REPORT_HUB.filter(e => e.father_id === this.reportType[this.selectedTab]?.id).map(e => e.node_name));

      if (tab === 0) {
        // this.selectedYear = this.yearsArray[0];
        this.itemsArray = [];
        this.rangeDate = [];
        this.search = null;
        this.itemsArray = this.latestReports;
      } else {
        const folder_name = `REPORT-HUB/${this.app_user.crm_id}/${this.serviceName}/${this.reportType[tab]?.type}/${this.selectedYear}/`;
        const items = this.policy.folderObjects.filter(str => str.startsWith(folder_name));
        this.itemsArray = items.map(e => ({
          reportType: this.reportType[tab]?.type,
          ReportName: e.replace(folder_name, ''),
          reportDate: this.extractDate(e.replace(folder_name, '')),
          s3_path: e
        }));
      }
    },
    generateLatestReport() {
      const missingReportType = [];

      this.reportType.forEach(e => {
        const folder = e.type;

        if (folder !== 'LatestReports') {
          const folder_name = `REPORT-HUB/${this.app_user.crm_id}/${this.serviceName}/${folder}/${this.selectedYear}/`;
          const items = this.policy.folderObjects.filter(str => str.startsWith(folder_name));
          const reports = items.map(report_path => ({
            reportType: folder,
            ReportName: report_path.replace(folder_name, ''),
            reportDate: this.extractDate(report_path.replace(folder_name, '')),
            s3_path: report_path
          }));
          const timesStamps = reports.map(e => moment(e.reportDate).unix());
          const max = Math.max(...timesStamps.filter(value => !isNaN(value)));
          this.latestReports.push(...reports.filter(e => moment(e.reportDate).unix() === max));
        }
      });

      this.reportType.map(e => e.type).slice(1).forEach(e => {
        if (!this.latestReports.map(e => e.reportType).includes(e)) {
          missingReportType.push(e);
        }
      });

      missingReportType.forEach(folder => {
        const folder_name = `REPORT-HUB/${this.app_user.crm_id}/${this.serviceName}/${folder}/${this.selectedYear - 1}/`;
        const items = this.prevYearArray.filter(str => str.startsWith(folder_name));
        const reports = items.map(report_path => ({
          reportType: folder,
          ReportName: report_path.replace(folder_name, ''),
          reportDate: this.extractDate(report_path.replace(folder_name, '')),
          s3_path: report_path
        }));
        const timesStamps = reports.map(e => moment(e.reportDate).unix());
        const max = Math.max(...timesStamps.filter(value => !isNaN(value)));
        this.latestReports.push(...reports.filter(e => moment(e.reportDate).unix() === max));
      });
    },
    changeFilterDate() {
      this.$refs.filterReportHubMenu.save(this.rangeDate);
    },
    closeDateRange() {
      this.rangeMenu = false;
      this.rangeDate = [];
      this.$refs.filterReportHubMenu.save([]);
    },
    fetchAllowedDates() {
      const datesArray = [];
      const startOfYear = moment(this.selectedYear, 'YYYY').startOf('year');
      const endOfYear = moment(this.selectedYear, 'YYYY').endOf('year');

      const currentDate = startOfYear.clone();

      while (currentDate.isSameOrBefore(endOfYear)) {
        datesArray.push(currentDate.format('YYYY-MM-DD'));
        currentDate.add(1, 'day');
      }

      return datesArray;
    },
    filterAllowedDates(date) {
      return this.allowedDates.includes(date);
    },
    generateYearsArray() {
      const currentYear = moment().format('YYYY');
      const startYear = parseInt(currentYear) - 10;

      return Array.from({ length: parseInt(currentYear) - parseInt(startYear) + 1 }, (_, i) => parseInt(startYear) + i).reverse();
    },
    isLandscape() {
      return /Mobi|Android/i.test(navigator.userAgent) && window.innerHeight < window.innerWidth;
    },
    mobileBreakpoints() {
      return /Mobi|Android/i.test(navigator.userAgent) || this.isLandscape();
    }
  }
};
</script>

<style scoped>
.flex-margin {
  margin-right: 2%;
  margin-top: 2%
}
.card-title {
  color: #0da344;
  background-color: #f4f3f3;
  font-size: 22px;
}

@media (max-device-width: 599px) {
  .flex-margin {
    margin-bottom: 2%;
  }

  .col-12 > .v-input {
    padding-top: 2px!important;
    align-items: center;
  }
}

@media (min-device-width: 600px) {
  .flex-margin {
    margin-left: calc(100px + 2%);
  }
}

</style>
